<template>
  <div class="">
    <!-- Content Header (Page header) -->
    <div class="">
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Main row -->
          <div class="row">
            <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
              <div class="card">
                <div class="card-body table-responsive table-head">
                  <div class="row bor-bot pb-2">
                    <div class="col-md-8 pl-0">
                      <h1 class="m-0 text-dark pt-2 pb-2">{{ this.getSiteProps('general.admin-ncsa-team-report-title') || 'Recruiting Team Registration Reports' }}</h1>
                    </div>
                    <div class="col-md-4">
                      <div class="text-right pb-3">
                        <ol class="breadcrumb float-sm-right">
                          <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                          <li class="breadcrumb-item"><Breadcrumbs/></li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive mt-4"  id="member_reg_report_table">
                    <div class="data-tablebutn pb-3">
                      <b-button class="btn-primary btn-darkblue btn mr-1" :class="{ 'filter-applied': filtersApplied }" v-b-modal.modal-club-report>
                        <i class="fas fa-sliders-h"></i> Filters
                      </b-button>
                      <b-button class="btn-primary btn-darkblue btn" @click="refreshList" v-if="filtersApplied">
                        <i class="fas fa-redo"></i> Reset
                      </b-button>
                      <b-button class="ml-1 btn-primary btn-darkblue btn" @click="exportCsv" :disabled="exportProgress">
                        <i class="fas" :class="[ exportProgress ? 'fa-circle-notch fa-spin' : 'fa-file-download' ]"></i> Export
                      </b-button>
                    </div>
                    <div class="search-box">
                      <input type="text" v-model="searchValue" class="input-field form-control" style="width:100%; max-width:234px" name="general_search" id="general_search" @keyup="queryForKeywords">
                      <i class="fas fa-search" style="position:absolute"></i>
                    </div>
                    <table class="table table-sm table-bordered table-hover" id="list_table">
                      <thead class="thead-dark">
                      <tr>
                        <th class="all">Team Id</th>
                        <th class="all">Role/Contact Type</th>
                        <th class="all">Coach Name</th>
                        <th class="all">Email</th>
                        <th class="all">Phone Number</th>
                        <th class="none">City</th>
                        <th class="none">State</th>
                        <th class="none">Age Division</th>
                        <th class="none">Recruiting</th>
                        <th class="all">Team Name</th>
                        <th class="none">League</th>
                        <th class="none">Zip Code</th>
                        <th class="all">Date Paid</th>
                      </tr>
                      </thead>
                      <tbody>
                      <!-- Data will be placed here -->
                      <tr>
                        <td colspan="9">&nbsp;</td>
                      </tr>
                      <tr>
                        <td colspan="9">&nbsp;</td>
                      </tr>
                      </tbody>
                    </table>
                  </div>

                  <b-modal id="modal-club-report" v-bind:hide-footer="true" ref="modal-club-report" size="lg"  title="Advanced Search">
                    <form ref="form" method="post" @submit.prevent="advancedSearch()">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">{{this.getSiteProps('general.admin-team') || 'Club'}} Id</label>
                            <input type="text" class="form-control" id="confirmation_code" v-model="searchForm.confirmation_code">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">{{this.getSiteProps('general.admin-team') || 'Club'}} Name</label>
                            <input id="club_name" type="text" class="form-control" v-model="searchForm.club_name">
                            <div class="text-sm text-danger">{{ errors.club_name }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6" v-if="subdomain != 'alliancefastpitch'" v-show="fieldsShow">
                          <div class="form-group">
                            <label for="club_type" class="form-label">Type</label><br>
                            <select id="club_type" name="club_type" class="form-control" v-model="searchForm.club_type">
                              <option value="">Select</option>
                              <template v-if="subdomain == 'shooting'">
                                <option v-for="ctype in clubTypes" :value="ctype"
                                        :key="'cT-'+ctype">{{ ctype }}</option>
                              </template>
                              <template v-else>
                                <option v-for="(typ, val) in clubType" :value="val"
                                        :key="val">{{ typ }}</option>
                              </template>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="subdomain != 'alliancefastpitch'" v-show="fieldsShow">
                          <div class="form-group">
                            <label class="form-label">{{ subdomain == 'waterski' ? 'First Name' : 'Admin Name' }}</label>
                            <input type="text" id="first_name" class="form-control" v-model="searchForm.first_name">
                          </div>
                        </div>
                        <div class="col-md-6" v-if="subdomain == 'waterski'">
                          <div class="form-group">
                            <label class="form-label">Last Name</label>
                            <input type="text" id="last_name" class="form-control" v-model="searchForm.last_name">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Effective From</label>
                            <div class="input-group birthdate">
                              <div class="birthdaypicker">
                                <date-pick
                                    v-model="searchForm.registration_start_date"
                                    :format="'MM/DD/YYYY'"
                                    :displayFormat="'MM/DD/YYYY'"
                                    v-mask="'##/##/####'"
                                    :parseDate="parseDatePick"
                                ></date-pick>
                              </div>
                            </div>
                            <input id="registration_start_date" type="hidden" class="form-control" :value="registration_start_date_1">
                            <div class="text-sm text-danger">{{ errors.registration_start_date }}</div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Efferctive To</label>
                            <div class="input-group birthdate">
                              <div class="birthdaypicker">
                                <date-pick
                                    v-model="searchForm.registration_end_date"
                                    :format="'MM/DD/YYYY'"
                                    :displayFormat="'MM/DD/YYYY'"
                                    v-mask="'##/##/####'"
                                    :parseDate="parseDatePick"
                                ></date-pick>
                              </div>
                            </div>
                            <input id="registration_end_date" type="hidden" class="form-control" :value="registration_end_date_1">
                            <div class="text-sm text-danger">{{ errors.registration_end_date }}</div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Recruiting</label>
                            <select id="team_ncsa" class="form-control" v-model="searchForm.team_ncsa">
                              <option value="">Select</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" v-show="fieldsShow">
                          <div class="form-group">
                            <label class="form-label">Registration Year</label>
                            <select id="year1" class="form-control" v-model="searchForm.year">
                              <option selected="selected" value="">Select</option>
                              <template v-for="year in years">
                                <option v-if="year >= 2018" :value="year" :key="'year' + year">{{ year }}</option>
                              </template>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="Object.keys(membershipData).length > 1" v-show="fieldsShow">
                          <div class="form-group">
                            <label class="form-label">Membership</label>
                            <select id="membership_id" name="membership_id" class="form-control" v-model="searchForm.membership_id">
                              <option value="">Select</option>
                              <option v-for="(id, val) in membershipData" :value="val" :key="val">{{ id }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" v-show="fieldsShow">
                          <div class="form-group">
                            <label class="form-label">Status</label>
                            <!-- <input type="text" class="form-control"> -->
                            <select id="status" name="status" class="form-control" v-model="searchForm.status">
                              <option value="">Select</option>
                              <template v-for="(id, val) in membershipStatus">
                                <option v-if="val > 0 && val < 3" :value="val" :key="val">{{ id }}</option>
                              </template>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" v-show="fieldsShow">
                          <div class="form-group">
                            <label class="form-label">Transaction Id</label>
                            <input type="text" class="form-control" id="transaction_id" v-model="searchForm.transaction_id">
                          </div>
                        </div>
                        <div class="col-md-6" v-if="subdomain == 'waterski'" v-show="fieldsShow">
                          <div class="form-group">
                            <label class="form-label">Region</label>
                            <input type="text" id="region_name" class="form-control" v-model="searchForm.region_name">
                          </div>
                        </div>
                        <div class="col-md-6" v-if="subdomain == 'waterski'" v-show="fieldsShow">
                          <div class="form-group">
                            <label class="form-label">Primary Site State</label>
                            <select id="site_state" class="form-control" v-model="searchForm.site_state" name="searchForm.site_state" >
                              <option value="">Select</option>
                              <option  v-for="listOfState in listOfStates" :key="listOfState.state_code" :value="listOfState.state_code">{{ listOfState.state }}</option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="subdomain == 'alliancefastpitch'" v-show="fieldsShow">
                          <div class="form-group">
                            <label class="form-label">League</label>
                            <select class="form-control" v-model="searchForm.member_league" id="member_league">
                              <option value="">Select</option>
                              <option v-for="(leagueData,key) in teamLeague" :key="key" :value="leagueData.id">
                                {{ leagueData.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-6" v-if="subdomain == 'alliancefastpitch'" v-show="fieldsShow">
                          <div class="form-group">
                            <label class="form-label">League</label>
                            <select class="form-control" v-model="searchForm.membership_type" id="member_league">
                              <option value="">Select</option>
                              <option v-for="(leagueData,key) in membershipType" :key="key" :value="leagueData.text">
                                {{ leagueData.text }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div v-if="subdomain == 'alliancefastpitch'" class="col-md-6" v-show="fieldsShow">
                          <div class="form-group">
                            <label for="age_division" class="form-label">Division </label>
                            <select class="form-control" @change="getTeam()" id="graduation_year"
                                    v-model="searchForm.age_division" name="playerTransferForm.age_division">
                              <option value="">Select Graduation Year</option>
                              <option v-for="year in yearArr" :value="year" :key="year">{{ year }}</option>
                            </select>
                            <span class="text-sm text-danger"></span>
                          </div>
                        </div>
                        <div class="col-md-12 modal-searchbtn text-center">
                          <div class="form-group">
                            <button type="submit" class="btn btn-primary btn-blue">{{ button.SearchButton }}</button>
                          </div>
                          <div class="text-sm text-danger">{{ errors.not_found }}</div>
                        </div>
                      </div>
                    </form>
                  </b-modal>
                </div>
              </div>
            </section>
          </div>
          <!-- /.row (main row) -->
        </div><!-- /.container-fluid -->
      </section>
    </div>
    <!-- /.content -->
    <Footer></Footer>
  </div>
</template>

<script>
// import DownloadExcel from "vue-json-excel";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import moment from 'moment';
import $ from "jquery";
import DatePick from "vue-date-pick";
import axios from 'axios';

const validateName = name => {
  if (name.length || name != "") {
    return { valid: true, error: null };
  }
  return { valid: false, error: null };
};

let cachedData = {};
export default {
  components: { DatePick },
  data() {
    return {
      exportProgress: false,
      filtersApplied: false,
      loaded: false,
      perPage: 10,
      currentPage: 1,
      fields: [
        {
          key :'team_name',
          label :'Team Name',
          sortable: true
        },
        {
          key :'type',
          label :'Type',
          sortable: true
        },
        {
          key :'first_name',
          label :'First Name',
          sortable: true
        },
        {
          key :'last_name',
          label :'Last Name',
          sortable: true
        },
        {
          key :'updated_at',
          label :'Registered',
          sortable: true
        },
        {
          key: 'status',
          label :'Status',
          sortable: true,
        },
        {
          key: 'transaction_id',
          sortable: true,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
      items: [],
      viewEntryOptions: [
        { value: 5, text: '5' },
        { value: 10, text: '10' },
        { value: 20, text: '20' },
        { value: 20, text: '50' },
        { value: 20, text: '100' },
      ],
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      searchForm: {
        registration_start_date: '',
        registration_end_date: '',
        team_ncsa:'',
        graduation_year:''
      },
      button: {
        SearchButton: 'Search'
      },
      errors: {},
      table: {},
      clubTypes: [],
      membershipData: [],
      teamLeague:[],
      disciplines:[],
      disciplines_list:[],
      ageDivisionOPt: [
        {"id": "4", "text": "12U"},
        {"id": "5", "text": "10U"}
      ],
      membershipType: [
        {"id": "1", "text": "Individual"},
        {"id": "4", "text": "Club"}
      ],
      yearArr:[],
      fieldsShow:false
    }
  },
  methods: {
    queryForKeywords(event) {
      const value = event.target.value
      this.keywords = value
      if(!isNaN(value) && value != '' && value.length == 9 ){
        this.searchForm.confirmation_code = value;
        console.log('if',value);
        this.advancedSearch();
      }else{
        this.searchForm.confirmation_code = '';
        console.log('else',value);
        this.table.search(value).draw();
      }
    },
    formatDate(value)
    {
      var date = moment(value, "YYYY-MM-DD hh:mm:ss");
      if(date.isValid()){
        return date.format('MM/DD/YYYY');
      } else {
        return 'N/A';
      }
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    viewRow(item, index, button){
      this.infoModal.title = item.first_name + ' ' + item.last_name;
      this.infoModal.content = item;
      this.$root.$emit('bv::show::modal', this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    editRow(id){
      console.log('Editing row...' + id);
    },
    deleteRow(id){
      console.log('Deleting row...' + id);
    },
    exportCsv(){
      var self = this;
      $.ajax({
        url: process.env.VUE_APP_URL + "api/reports/export/ncsa-registrations/Club",
        type: 'post',
        "data": this.table.ajax.params(),
        beforeSend: function(){
          self.exportProgress = true;
        },
        "success": function(res, status, xhr) {
          self.exportProgress = false;
          if(res.file_url != undefined) window.open(res.file_url, '_blank');
        },
        error: function(err){
          console.log(err);
          self.exportProgress = false;
        }
      });
    },
    refreshList(event){
      // this.table.clear().draw();
      this.searchForm = {
        team_ncsa:'',
        confirmation_code:'',
        first_name: '',
        last_name: '',
        registration_start_date :'',
        registration_end_date:'',
        membership_id: '',
        club_name: '',
        club_type: '',
        year: '',
        status: '',
        transaction_id: '',
        member_league:'',
        age_division: '',
        season:''
      };
      this.table.ajax.reload();
      this.filtersApplied = false;
    },
    validate() {
      this.errors = {}
      this.valid =  false;
      console.log('-------------');
      console.log(this.searchForm);

      const validStartDate = validateName(this.searchForm.registration_start_date);
      this.errors.registration_start_date = validStartDate.error;

      const validEndDate = validateName(this.searchForm.registration_end_date);
      this.errors.registration_end_date = validEndDate.error;

      var dateValid = false;
      if(validStartDate.valid && !this.validateDate(this.searchForm.registration_start_date)){
        dateValid = true;
        this.errors.registration_start_date = "Invalid Date";
      }
      if(validEndDate.valid && !this.validateDate(this.searchForm.registration_end_date)){
        dateValid = true;
        this.errors.registration_end_date = "Invalid Date";
      }

      if(dateValid == false){
        if((validStartDate.valid && validEndDate.valid) && (moment(this.searchForm.registration_end_date).isBefore(this.searchForm.registration_start_date))) {
          this.errors.registration_end_date = 'End date must be greater than Start date!';
          return this.valid =  false;
        } else {
          this.valid = true;
        }
      } else {
        this.valid = false;
      }

      return this.valid;
    },
    loadData: function (){
      //if(Object.keys(cachedData).length === 0){
      console.log(this.searchForm.state);
      axios.post(process.env.VUE_APP_URL + 'api/reports/registration/Club', this.searchForm)
          .then((response) => {
            console.log(response.data.registrations);
            cachedData = response.data.registrations;
            this.items = cachedData;
            this.loaded = true;
            this.button.SearchButton = 'Search';
            this.items = cachedData;
            this.loaded = true;
            if(this.loaded == true) {
              console.log(this.button.SearchButton);
              this.$refs['modal-club-report'].hide();
            }
            return true;
          })
          .catch(error => this.errors = error.response.data);
      //}

    },
    advancedSearch() {

      if(!this.validate()) {
        return;
      }
      console.log(this.button.SearchButton);
      this.button.SearchButton = 'Searching...';
      console.log(this.button.SearchButton);
      // Prevent modal from closing
      //bvModalEvt.preventDefault()
      this.loaded = false;
      // Trigger submit handler
      this.table.draw();
      this.filtersApplied = true;
      //console.log(this.button.SearchButton);
      this.$refs['modal-club-report'].hide();
      this.button.SearchButton = 'Search';
    },
  },
  mounted(){
    $.ajaxSetup({
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      }
    });
    var self = this;
    var type = self.searchForm.membership_type
    this.table = $("#list_table").DataTable({
      sDom: "lfrtip",
      processing: true,
      serverSide: true,
      pageLength: 10,
      responsive: true,
      "bFilter": true,
      ajax: {
        url: process.env.VUE_APP_URL + "api/reports/ncsa/team",
        type: 'post',
        data: function (d) {
          d.confirmation_code = $('#confirmation_code').val() || self.searchForm.confirmation_code;
          d.club_name = $('#club_name').val() || self.searchForm.club_name;
          d.team_ncsa = $('#team_ncsa').val() || self.searchForm.team_ncsa;
          d.start_date = $('#registration_start_date').val() || self.searchForm.start_date;
          d.end_date = $('#registration_end_date').val() || self.searchForm.end_date;
        }
      },
      columns: [
        { data: 'confirmation_code', name: 'member.confirmation_code', render: (data)=>{return data || "N/A";}},
        { data: "contact_type", name: "contact_type", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch" },
        { data: "tc_full_name", name: "tc_full_name", render: (data)=>{return data || "N/A"},searchable:false, visible: this.subdomain == "alliancefastpitch" },
        { data: "head_coach_email", name: "registration.team.email", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch" },
        { data: "head_coach_phone", name: "head_coach_phone", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch" },
        { data: "city", name: "registration.team.city", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch" },
        { data: "state", name: "registration.team.state_code", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch" },
        { data: "age_division", name: "registration.team.age_division", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch",searchable: false },
        { data: "membership_benefits", name: "membership_benefits", render: (data)=>{return data || "N/A"}, visible: this.subdomain == "alliancefastpitch" },
        { data: 'team_name', name: (this.subdomain=='alliancefastpitch') ? 'registration.team.name' :'team.name', render: value => value || 'N/A'},
        { data: "member_league", name: "registration.team.member_league", render: (data)=>{return data || "N/A"},searchable: false, visible:this.subdomain == "alliancefastpitch" },
        { data: "head_coach_zip", name: "head_coach_zip", render: (data)=>{return data || "N/A"},searchable: false, visible:this.subdomain == "alliancefastpitch" },
        { data: "transaction_date", name: "registration.transaction_date", render: (data)=>{return this.formatDate(data) || "N/A";} },

      ],
      columnDefs: [
        { targets: 0, orderable: false, visible: this.subdomain=='alliancefastpitch' },
      ],
      order: [[1, "asc"]]
    });

    const year = new Date().getFullYear();
    const yrs = year+20;
    const twoDigitYear = yrs.toString().substr(-2);
    const lengthOfYear = parseInt(20) + parseInt(twoDigitYear);
    this.yearArr = Array.from({length: lengthOfYear}, (value, index) => yrs - index);
  },

  computed: {
    rows() {
      return this.items.length
    },
    years () {
      return new Date().getFullYear() + 1;
    },
    registration_start_date_1: function() {
      return this.searchForm.registration_start_date;
    },
    registration_end_date_1: function() {
      return this.searchForm.registration_end_date;
    }
  }
}

</script>